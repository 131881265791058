import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.match.js";
import HapticButton from '~/components/haptic/HapticButton.vue';
export default {
  components: {
    PanelButton: function PanelButton() {
      return import('~/components/panels/PanelButton.vue');
    },
    DdButton: function DdButton() {
      return import('./DdButton.vue');
    },
    HapticMenu: function HapticMenu() {
      return import('~/components/haptic/HapticMenu.vue');
    },
    HapticButton: HapticButton
  },
  data: function data() {
    return {
      saleUrl: '/creativepractice/christmas-specials',
      saleStart: new Date('2021-12-12T22:00:00.000Z'),
      saleEnd: new Date('2022-01-07T22:00:00.000Z'),
      routesToHide: ['creativepractice-course-slug', 'creativepractice-course-course-module-module', 'creativepractice-course-course-module-module-test', 'creativepractice-results-id', 'conference-cases-product-design-2021', 'conference-cases-marketing-strategy-pr-2021', 'conference-cases-product-design-2022',
      // 'conference-dysarium',
      'cases-design-challenge', 'cases-special-event', 'login', 'registration', 'forgotpassword', 'changepassword', 'editor(-.*)?']
    };
  },
  computed: {
    hasUnreadNotification: function hasUnreadNotification() {
      return useNotificationsStore().hasUnread;
    },
    hideMenu: function hideMenu() {
      var routeName = this.$route.name;
      if (!routeName) return false;
      var match = function match(r) {
        return routeName.match(r + '___');
      };
      return this.routesToHide.some(match);
    },
    openedHaptic: function openedHaptic() {
      return this.$modalityStore.hapticMenu;
    },
    authPanelStore: function authPanelStore() {
      return useAuthPanelStore();
    },
    vacanciesUrl: function vacanciesUrl() {
      return this.$auth.user ? '/my/jobsearch' : '/vacancies';
    }
  },
  methods: {
    hover: function hover(name, e) {
      var _this = this;
      var delay = this.openedHaptic ? 0 : 500;
      setTimeout(function () {
        if (e.target.matches(':hover')) _this.openHapticMenu(name);
      }, delay);
    },
    onClick: function onClick(name, e) {
      if (e.which == 3) {
        e.preventDefault();
        this.openHapticMenu(name);
      }
    },
    register: function register() {
      this.authPanelStore.$patch({
        direction: 'left',
        state: 'register'
      });
    },
    login: function login() {
      this.authPanelStore.$patch({
        direction: 'right',
        state: 'login'
      });
    },
    scrollTop: function scrollTop(path) {
      if (this.$route.path == path) this.$scrollTo('body');

      // home feed
      if (path.match('/my/') && this.$route.path.match('/my/feed')) {
        this.$scrollTo('body');
      }
    },
    openHapticMenu: function openHapticMenu(name) {
      this.$modalityStore.openModal({
        type: 'hapticMenu',
        name: name
      });
    }
  }
};