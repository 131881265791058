import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import Card from '~/components/cards/Card.vue';
import CasesNavigationMenu from './CasesNavigationMenu.vue';
import CasesFooter from '~/components/CasesFooterMain.vue';
import Cookie from '~/components/Cookie.vue';
export default {
  name: 'CasesContent',
  components: {
    Cookie: Cookie,
    Card: Card,
    CasesFooter: CasesFooter,
    CasesNavigationMenu: CasesNavigationMenu,
    DrawerTint: function DrawerTint() {
      return import('./DrawerTint.vue');
    },
    DropdownTint: function DropdownTint() {
      return import('./DropdownTint.vue');
    },
    CasesPanel: function CasesPanel() {
      return import('./panels/CasesPanel.vue');
    },
    Dropdown: function Dropdown() {
      return import('./CasesDropdown.vue');
    },
    Hint: function Hint() {
      return import('~/components/hints/Hint.vue');
    },
    AuthPanel: function AuthPanel() {
      return import('./panels/Auth.vue');
    },
    NotificationPanel: function NotificationPanel() {
      return import('~/components/notifications/Notifications.vue');
    },
    UsersList: function UsersList() {
      return import('~/components/panels/UsersList.vue');
    },
    Gains: function Gains() {
      return import('~/components/Gains.vue');
    },
    Banner: function Banner() {
      return import('~/components/banners/Trial.vue');
    },
    Sale: function Sale() {
      return import('~/components/banners/Sale.vue');
    },
    Incubator: function Incubator() {
      return import('~/components/banners/Incubator.vue');
    },
    PriceChange: function PriceChange() {
      return import('~/components/banners/PriceChange.vue');
    },
    Cart: function Cart() {
      return import('~/components/cart/Cart.vue');
    },
    Subscription: function Subscription() {
      return import('~/components/cart/Subscription.vue');
    },
    Collapse: function Collapse() {
      return import('~/components/collapse/Collapse.vue');
    },
    CollapseButton: function CollapseButton() {
      return import('~/components/collapse/Button.vue');
    },
    ProfileInformer: function ProfileInformer() {
      return import('~/components/profile/Informer.vue');
    },
    TrialPanel: function TrialPanel() {
      return import('~/components/panels/Trial.vue');
    },
    WebpPicture: function WebpPicture() {
      return import('~/components/WebpPicture.vue');
    },
    CookieSettings: function CookieSettings() {
      return import('~/components/panels/CookieSettings');
    }
  },
  data: function data() {
    return {
      saleStart: new Date('2024-07-24T21:00:00.000Z'),
      // saleStart: false,
      saleEnd: new Date('2024-08-05T20:59:59.000Z'),
      incubatorEnd: new Date('2024-05-31T20:59:59.000Z'),
      loading: true,
      showBanner: false,
      showSale: false,
      showIncubator: false,
      showPriceChange: false
    };
  },
  computed: {
    usersListStore: function usersListStore() {
      return useUsersListStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    cartStore: function cartStore() {
      return useCartStore();
    },
    hasSubscription: function hasSubscription() {
      return this.subscriptionStore.token;
    },
    trialAvailable: function trialAvailable() {
      return this.subscriptionStore.trialVisible;
    },
    isDesctop: function isDesctop() {
      return ['lg', 'xl', 'xxl'].includes(this.$mq);
    },
    companies: function companies() {
      if (this.$auth.user && this.$auth.user.moders) {
        return this.$auth.user.moders.map(function (o) {
          return o.company;
        });
      } else {
        return [];
      }
    },
    cartEmpty: function cartEmpty() {
      return this.cartStore.isEmpty;
    },
    cookieConsentVisible: function cookieConsentVisible() {
      return this.$cookieStore.cookieConsentVisible;
    }
  },
  watch: {
    // Show banner in 10 sec after cookie consent closed
    cookieConsentVisible: function cookieConsentVisible(val, oldVal) {
      var _this = this;
      if (oldVal && !val) {
        setTimeout(function () {
          _this.pickBanner();
        }, 10000);
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    // Show banner in 5 sec if cookie consent already accespted
    if (!this.cookieConsentVisible) {
      setTimeout(function () {
        _this2.pickBanner();
      }, 5000);
    }
  },
  methods: {
    pickBanner: function pickBanner() {
      var now = new Date();
      var priceChangeEnd = new Date('2024-02-29T22:00:00.000Z');
      var saleStarted = !this.saleStart || now >= this.saleStart;
      var saleEnded = now >= this.saleEnd;
      var priceChanged = now >= priceChangeEnd;
      var incubatorEnded = now >= this.incubatorEnd;
      if (saleStarted && !saleEnded) {
        this.showSale = true;
      } else if (!incubatorEnded) {
        this.showIncubator = true;
      } else if (!this.hasSubscription && this.trialAvailable) {
        this.showBanner = true;
      } else if (!priceChanged) {
        this.showPriceChange = true;
      }
    }
  }
};