import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.array.includes.js";
import DdButton from './DdButton.vue';
import PanelButton from './panels/PanelButton.vue';
import WebpPicture from '~/components/WebpPicture.vue';
export default {
  name: 'CasesMenu',
  components: {
    DdButton: DdButton,
    PanelButton: PanelButton,
    WebpPicture: WebpPicture,
    CasesDropdown: function CasesDropdown() {
      return import('./CasesDropdown.vue');
    },
    Collapse: function Collapse() {
      return import('~/components/collapse/Collapse.vue');
    },
    CollapseButton: function CollapseButton() {
      return import('~/components/collapse/Button.vue');
    },
    Checkbox: function Checkbox() {
      return import('~/components/form/Checkbox.vue');
    },
    AcceptModer: function AcceptModer() {
      return import('~/components/cases/AcceptModer.vue');
    },
    ProfilePanel: function ProfilePanel() {
      return import('~/components/panels/ProfilePanel.vue');
    },
    LanguageSettings: function LanguageSettings() {
      return import('~/components/editor/LanguageSettings.vue');
    }
  },
  data: function data() {
    return {
      saleUrl: '/creativepractice/christmas-specials',
      saleStart: new Date('2021-12-12T22:00:00.000Z'),
      saleEnd: new Date('2022-01-07T22:00:00.000Z')
    };
  },
  computed: {
    isSaleActive: function isSaleActive() {
      var now = new Date();
      return now >= this.saleStart && now <= this.saleEnd;
    },
    hasUnreadNotification: function hasUnreadNotification() {
      return useNotificationsStore().hasUnread;
    },
    locale: function locale() {
      return this.$i18n.locale;
    },
    cartStore: function cartStore() {
      return useCartStore();
    },
    cartEmpty: function cartEmpty() {
      return this.cartStore.isEmpty;
    },
    moders: function moders() {
      if (!this.$auth.user || !this.$auth.user.moders) return [];
      return this.$auth.user.moders.filter(function (o) {
        return o.approved;
      });
    },
    moderInvites: function moderInvites() {
      if (!this.$auth.user || !this.$auth.user.moders) return [];
      return this.$auth.user.moders.filter(function (o) {
        return !o.approved;
      });
    },
    activeProfile: function activeProfile() {
      return this.appStore.activeProfile;
    },
    appStore: function appStore() {
      return useAppStore();
    }
  },
  watch: {
    $route: function $route(newRoute, oldRoute) {
      this.seActiveProfile();
    },
    locale: function locale(newVal) {
      this.$gtag('set', 'user_properties', {
        interface_locale: newVal
      });
    }
  },
  created: function created() {
    this.seActiveProfile();
  },
  mounted: function mounted() {
    this.$gtag('set', 'user_properties', {
      color_mode: this.defineUserTheme(),
      interface_locale: this.locale
    });
  },
  methods: {
    openCart: function openCart(index) {
      this.$modalityStore.closeDropdown({
        name: 'cart-menu'
      });
      this.cartStore.setActiveCartIndex(index);
      this.$modalityStore.openModal({
        type: 'cart',
        name: 'def'
      });
    },
    seActiveProfile: function seActiveProfile() {
      if (!this.$auth.user) return;
      if (this.$route.path.match('/company/')) {
        var slug = this.$route.path.split('/')[2];
        var moder = this.moders.find(function (o) {
          return o.company.slug == slug;
        });
        if (moder) {
          return this.appStore.setState({
            key: "activeProfile",
            value: {
              type: 'company',
              pic: moder.company.logo,
              name: moder.company.name,
              slug: moder.company.slug
            }
          });
        }
      }
      this.appStore.setState({
        key: "activeProfile",
        value: {
          type: 'user',
          pic: this.$auth.user.photo.avatar,
          name: this.$auth.user.displayName,
          slug: this.$auth.user.slug
        }
      });
    },
    openLanguageMenu: function openLanguageMenu() {
      var _this = this;
      this.$modalityStore.closeDropdown({
        name: 'user-menu'
      });
      setTimeout(function () {
        _this.$modalityStore.openDropdown({
          name: 'locale-toggle'
        });
      }, 100);
    },
    setTheme: function setTheme(val) {
      this.$cookieStore.setState("theme", val);
      this.$gtag('set', 'user_properties', {
        color_mode: this.defineUserTheme()
      });
    },
    scrollTop: function scrollTop(path) {
      if (this.$route.path.match('/my/feed') && path.match('/my/feed')) this.$scrollTo('body');
    },
    defineUserTheme: function defineUserTheme() {
      var theme = this.$cookieStore.theme;
      var GA_mode = 'Light';
      if (!['light', 'dark'].includes(theme)) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          theme = 'dark';
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
          theme = 'light';
        }
      }
      if (theme == 'light') GA_mode = 'Light';
      if (theme == 'dark') GA_mode = 'Dark';
      this.appStore.$patch({
        colorMode: theme
      });
      return GA_mode;
    }
  }
};