<template>
    <nuxt-link
        :to="localePath(path)"
        @click.native="scrollTop(localePath(path))"
        :class="{ open: isOpen }"
        @mouseover.native="hover"
        :target="target"
        @mouseleave.native="$modalityStore.hapticTimeoutClose()"
        @mouseenter.native="$modalityStore.clearHapticTimeoutClose()"
    >
        <slot />
    </nuxt-link>
</template>

<script>
export default {
    props: ['name', 'path', 'target'],

    data () {
        return {
            menu: null
        }
    },

    computed: {
        openedHaptic () {
            return this.$modalityStore.hapticMenu
        },

        isOpen () {
            return this.openedHaptic == this.name;
        }
    },

    methods: {
        hover (e) {
            let delay = this.openedHaptic ? 0 : 300;

            setTimeout(() => {
                if (e.target.matches(':hover')) this.openHapticMenu(this.name);
            }, delay)
        },

        oncontextmenu (e) {
            e.preventDefault();
            this.openHapticMenu(this.name);
        },

        openHapticMenu (name) {
            this.$modalityStore.openModal({
                type: 'hapticMenu',
                name
            })
        },

        scrollTop (path) {
            if (this.$route.path == path) this.$scrollTo('body')
        },
    },

    mounted () {
        this.$el.addEventListener('contextmenu', this.oncontextmenu);
        this.menu = document.getElementById('haptic-menu');
    },

    destroyed () {
        this.$el.removeEventListener('contextmenu', this.oncontextmenu);
    }
}
</script>
